import * as actionTypes from 'actions';

const initialState = {
  loggedIn: true,
  user: {
    first_name: '',
    last_name: '',
    email: '',
    avatar: '',
    role: '',
    access_token: '',
    username: '',
    fullname: '',
    role_name: '',
    note: '',
    company_name: ''
  }
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      return {
        ...state,
        user: { ...state.user, ...action.data }
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        user: {
          role: 'GUEST'
        }
      };
    }

    case actionTypes.SESSION_SET_INFO: {
      return {
        ...state,
        loggedIn: false,
        user: { ...state.user, ...action.data }
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
