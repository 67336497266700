import moment from 'moment';

import * as actionTypes from 'actions/orderManagementActions';
import { roundTimeQuarterHour } from 'utils/format';

const initFilter = {
  startDate: moment(roundTimeQuarterHour())
    .subtract(2, 'days')
    .toDate(),
  endDate: moment(roundTimeQuarterHour())
    .add(3, 'days')
    .toDate(),
  status: 'toan_bo'
};
const initialState = {
  listOrder: [],
  tabChoice: '',
  filter: initFilter,
  loading: false,
  color: '',
  selectedOrder: []
};

const orderManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTER_DATA: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.data
        }
      };
    }

    case actionTypes.SET_ORDERS: {
      return {
        ...state,
        listOrder: action.listOrder,
        color: action.color,
        loading: false,
        selectedOrder: []
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_SELECTED_ORDER: {
      return {
        ...state,
        selectedOrder: action.selected
      };
    }

    case actionTypes.SET_TAB_CHOICE: {
      return {
        ...state,
        tabChoice: action.tabChoice
      };
    }

    default: {
      return state;
    }
  }
};

export default orderManagementReducer;
