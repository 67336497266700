import moment from 'moment';
import {
  getOrderInfoApi,
  createOrderApi,
  getSuggestApi,
  updateOrderApi,
  orderCreateFormCostApi,
  getOrderTempInfo,
  createOrderTempApi,
  getOrderSenderInfoApi
} from 'apis';
import { first } from 'lodash'

const prefix = 'order';

export const SET_ACTIVE_STEP = `${prefix}SET_ACTIVE_STEP`;
export const SET_COMPLETED = `${prefix}SET_COMPLETED`;
export const SET_GENERAL_INFO = `${prefix}SET_GENERAL_INFO`;
export const SET_COST_INFO = `${prefix}SET_COST_INFO`;
export const UPDATE_DETAIL_INFO = `${prefix}UPDATE_DETAIL_INFO`;
export const UPDATE_ORDER_IMPORT = `${prefix}UPDATE_ORDER_IMPORT`;
export const ADD_ORDER = `${prefix}ADD_ORDER`;
export const SET_DETAIL_CHOOSE = `${prefix}SET_DETAIL_CHOOSE`;
export const RESET_ORDER = `${prefix}RESET_ORDER`;
export const SET_DETAIL_INFO = `${prefix}SET_DETAIL_INFO`;
export const UPDATE_VALIDATE = `${prefix}UPDATE_VALIDATE`;
export const DELETE_ORDER = `${prefix}DELETE_ORDER`;
export const DUPLICATE_ORDER = `${prefix}DUPLICATE_ORDER`;
export const SET_SUGGEST = `${prefix}SET_SUGGEST`;
export const SET_IMAGES = `${prefix}SET_IMAGES`;
export const SET_COST_FORM = `${prefix}SET_COST_FORM`;
export const IMPORT_ORDER = `${prefix}IMPORT_ORDER`;
export const SET_ORDER_SENDER_INFO = `${prefix}SET_ORDER_SENDER_INFO`;


export const setActiveStep = activeStep => dispatch => {
  dispatch({
    type: SET_ACTIVE_STEP,
    activeStep
  });
};

// export const setCompleted = completed => dispatch => {
//   dispatch({
//     type: SET_COMPLETED,
//     completed
//   });
// };

export const setGeneralInfo = data => dispatch => {
  dispatch({
    type: SET_GENERAL_INFO,
    data
  });
};

export const setCostInfo = data => dispatch => {
  dispatch({
    type: SET_COST_INFO,
    data
  });
};

export const updateDetailInfo = data => dispatch => {
  dispatch({
    type: UPDATE_DETAIL_INFO,
    data
  });
};

export const addOrder = () => dispatch => {
  dispatch({
    type: ADD_ORDER
  });
};

export const setDetailChoose = data => dispatch => {
  dispatch({
    type: SET_DETAIL_CHOOSE,
    data
  });
};

export const resetOrder = () => dispatch => {
  dispatch({
    type: RESET_ORDER
  });
};

const getDetailTip = infoDetail => {
  return infoDetail.map(info => ({
    id_don_hang: info.id_don_hang,
    ten_nguoi_gui: info.ten_nguoi_gui,
    sdt_nguoi_gui: info.sdt_nguoi_gui,
    senderAddress: info.noi_lay_hang,
    ten_nguoi_nhan: info.ten_nguoi_nhan,
    receiverAddress: info.noi_tra_hang,
    ten_cong_ty: info.ten_cong_ty,
    sdt_nguoi_nhan: info.sdt_nguoi_nhan,
    assistantName: info.ten_thau_phu,
    loai_don_hang: info.loai_don_hang,
    loai_hang_hoa: info.loai_hang_hoa,
    mo_ta_hang_hoa: info.mo_ta_hang_hoa,
    trong_luong: info.trong_luong,
    so_luong: String(info.so_luong),
    cbm: info.cbm,
    noi_lay_hang: info.noi_lay_hang,
    ngay_gio_lay_hang: new Date(info.ngay_gio_lay_hang),
    noi_tra_hang: info.noi_tra_hang,
    ngay_gio_tra_hang: new Date(info.ngay_gio_tra_hang),
    cod: info.cod,
    cuoc_van_chuyen: info.cuoc_van_chuyen,
    thanh_toan: info.thanh_toan,
    hinh_thuc_thanh_toan: info.hinh_thuc_thanh_toan,
    nguoi_thu_tien: info.nguoi_thu_tien,
    nguoi_thanh_toan: info.nguoi_thanh_toan,
    vat: info.vat,
    ghi_chu: info.ghi_chu,
    list_anh: info.list_anh
  }));
};

export const getOrderInfo = (id, isTemp) => async dispatch => {
  try {
    const orderInfo = isTemp
      ? await getOrderTempInfo(id)
      : await getOrderInfoApi(id);
    dispatch({
      type: SET_DETAIL_INFO,
      detailInformation: [orderInfo],
    });
  } catch (error) {
    console.log(error);
  }
};

const convertDataDetail = detailInformation => {
  return detailInformation.map(detail => ({
    ...detail,
    so_luong: Number(detail.so_luong),
    ngay_gio_lay_hang: moment(detail.ngay_gio_lay_hang).format(
      'YYYY-MM-DD HH:mm:ss'
    ),
    ngay_gio_tra_hang: moment(detail.ngay_gio_tra_hang).format(
      'YYYY-MM-DD HH:mm:ss'
    ),
  }));
};

export const createOrUpdateOrder = (
  isEdit,
  orderInfo,
  isTemp,
  setCompleted,
  enqueueSnackbar
) => async dispatch => {
  try {
    const list_don_hang = convertDataDetail(orderInfo.detailInformation)
    let res;
    if (isEdit) res = await updateOrderApi(first(list_don_hang));
    else if (isTemp) res = await createOrderTempApi({ list_don_hang });
    else res = await createOrderApi({ list_don_hang });
    setCompleted(true)

    enqueueSnackbar(res.message || `${isEdit ? 'Cập nhật' : 'Tạo'} đơn thành công`, { variant: 'success' })
    dispatch(resetOrder);
  } catch (error) {
    enqueueSnackbar(error.response.data.message || `Có lỗi trong quá trình 
      ${isEdit ? 'cập nhật' : 'tạo'} đơn, vui lòng thử lại sau`, { variant: 'error' })
  }
};

export const updateValidate = data => dispatch => {
  dispatch({
    type: UPDATE_VALIDATE,
    data
  });
};

export const updateOrderImport = data => dispatch => {
  dispatch({
    type: UPDATE_ORDER_IMPORT,
    data
  });
};

export const deleteOrder = () => dispatch => {
  dispatch({
    type: DELETE_ORDER
  });
};

export const importOrders = data => dispatch => {
  dispatch({
    type: IMPORT_ORDER,
    orders: getDetailTip(data)
  });
};

export const duplicateOrder = () => dispatch => {
  dispatch({
    type: DUPLICATE_ORDER
  });
};

export const getSuggest = () => async dispatch => {
  const suggestion = await getSuggestApi();
  dispatch({
    type: SET_SUGGEST,
    suggestion
  });
};

export const getCostForm = () => async dispatch => {
  const res = await orderCreateFormCostApi();
  dispatch({
    type: SET_COST_FORM,
    list_form_chi_phi: res.list_form_chi_phi
  });
};

export const getOrderSenderInfo = () => async dispatch => {
  const res = await getOrderSenderInfoApi();
  dispatch({
    type: SET_ORDER_SENDER_INFO,
    data: {
      ten_nguoi_gui: res.ten_nguoi_gui,
      sdt_nguoi_gui: res.sdt_nguoi_gui,
      nguoi_thanh_toan: res.ten_nguoi_gui,
      ten_cong_ty: res.ten_nguoi_gui
    }
  });
}