import { priceReportApi, getCustomersApi } from 'apis';
const prefix = 'priceReport';

export const SET_FILTER_DATA = `${prefix}_SET_FILTER_DATA`;
export const GET_DASHBOARD_REPORT = `${prefix}_GET_DASHBOARD_REPORT`;
export const GET_FILTER_DATA = `${prefix}_GET_FILTER_DATA`;

export const setFilter = filter => dispatch => {
  dispatch({
    type: SET_FILTER_DATA,
    filter
  });
};

export const getDashboardReport = data => async dispatch => {
  const res = await priceReportApi(data);
  const { toan_bo_cong_no, list_don, chart_data } = res;
  const dashboard = {
    cong_no: [],
    labels: []
  };
  chart_data.forEach(data => {
    dashboard.cong_no.push(data.cong_no);
    dashboard.labels.push(data.month);
  });
  dispatch({
    type: GET_DASHBOARD_REPORT,
    dashboard,
    toan_bo_cong_no,
    list_don
  });
};

export const getFilterData = () => async dispatch => {
  const customers = await getCustomersApi();
  dispatch({
    type: GET_FILTER_DATA,
    customers
  });
};
