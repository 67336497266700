import * as actionTypes from 'actions/revenueReportActions';
import moment from 'moment';

const initFilter = {
  customer: '',
  plate: '',
  start_date: moment()
    .subtract(120, 'days')
    .toDate(),
  end_date: moment()
    .add(30, 'days')
    .toDate()
};
const initialState = {
  dashboard: {
    doanh_thu: [],
    tong_chi_phi: [],
    tong_lai: [],
    labels: []
  },
  doanh_thu_toan_bo: 0,
  toan_bo_chi_phi: 0,
  tong_lai_toan_bo: 0,
  list_don: [],
  filter: initFilter,
  loading: false,
  customers: [],
  plates: [],
  table_data_key_name: [],
  bien_so_xe_table_data: []
};

const revenueReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTER_DATA: {
      return {
        ...state,
        filter: { ...state.filter, ...action.filter }
      };
    }

    case actionTypes.GET_DASHBOARD_REPORT: {
      return {
        ...state,
        dashboard: action.dashboard,
        doanh_thu_toan_bo: action.doanh_thu_toan_bo,
        toan_bo_chi_phi: action.toan_bo_chi_phi,
        tong_lai_toan_bo: action.tong_lai_toan_bo,
        list_don: action.list_don,
        table_data_key_name: action.table_data_key_name,
        bien_so_xe_table_data: action.bien_so_xe_table_data,
        loading: false
      };
    }

    case actionTypes.GET_FILTER_DATA: {
      return {
        ...state,
        customers: action.customers,
        plates: action.plates
      };
    }
    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    default: {
      return state;
    }
  }
};

export default revenueReportReducer;
