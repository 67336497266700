/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
// import React from 'react';
// import { colors } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
// import ChatIcon from '@material-ui/icons/ChatOutlined';
// import CodeIcon from '@material-ui/icons/Code';
// import DashboardIcon from '@material-ui/icons/DashboardOutlined';
// import ErrorIcon from '@material-ui/icons/ErrorOutline';
// import FolderIcon from '@material-ui/icons/FolderOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
// import ListAltIcon from '@material-ui/icons/ListAlt';
// import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
// import MailIcon from '@material-ui/icons/MailOutlined';
// import PresentToAllIcon from '@material-ui/icons/PresentToAll';
// import PeopleIcon from '@material-ui/icons/PeopleOutlined';
// import PersonIcon from '@material-ui/icons/PersonOutlined';
// import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
// import SettingsIcon from '@material-ui/icons/SettingsOutlined';
// import ViewModuleIcon from '@material-ui/icons/ViewModule';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import VideocamIcon from '@material-ui/icons/Videocam';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
// import WarningIcon from '@material-ui/icons/Warning';
// import GavelIcon from '@material-ui/icons/Gavel';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import AssistantIcon from '@material-ui/icons/Assistant';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
// import DateRangeIcon from '@material-ui/icons/DateRange';
// import FileCopyIcon from '@material-ui/icons/FileCopy';
import LockIcon from '@material-ui/icons/Lock';
// import DeleteIcon from '@material-ui/icons/Delete';
// import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
// import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

// import { Label } from 'components';

export default [
  {
    title: 'Pages',
    pages: [
      {
        title: 'Home',
        href: '/order-management',
        icon: HomeIcon
      },

      {
        title: 'Tạo đơn hàng',
        href: '/order/create',
        icon: CardTravelIcon
      },

      // {
      //   title: 'Tạo đơn chuyển trạm',
      //   href: '/route-management',
      //   icon: CardTravelIcon
      // },
      // {
      //   title: 'Quản lý chuyến đi',
      //   href: '/trip-management',
      //   icon: LocalShippingIcon
      // },
      // {
      //   title: 'Lọc đơn hàng',
      //   href: '/filter-order',
      //   icon: LocalShippingIcon
      // },
      {
        title: 'Danh sách đơn hàng',
        href: '/order-management',
        icon: LocalShippingIcon
      },
      {
        title: 'Danh sách thông báo',
        href: '/notice-management',
        icon: AssistantIcon
      },
      // {
      //   title: 'Đơn hàng chờ duyệt',
      //   href: '/order-waiting-approve-management',
      //   icon: HourglassEmptyIcon
      // },
      // {
      //   title: 'Quản lý thùng rác',
      //   href: '/trash-management',
      //   icon: DeleteIcon
      // },
      // {
      //   title: 'Quản lý excel tải lên',
      //   href: '/excel-management',
      //   icon: FileCopyIcon
      // },
      {
        title: 'Báo cáo',
        icon: BarChartIcon,
        children: [
          // {
          //   title: 'Tổng hợp công ty',
          //   href: '/general-report',
          //   icon: BarChartIcon
          // },
          // {
          //   title: 'Doanh thu',
          //   href: '/revenue-report',
          //   icon: BarChartIcon
          // },
          // {
          //   title: 'Công nợ',
          //   href: '/debt-report',
          //   icon: BarChartIcon
          // },
          {
            title: 'Chi phí',
            href: '/price-report',
            icon: BarChartIcon
          }
        ]
      },

      // {
      //   title: 'Quản lý',
      //   href: '/overview',
      //   icon: DashboardIcon,
      //   children: [
      //     {
      //       title: 'Chuyến đi',
      //       href: '/trip-management',
      //       icon: LocalShippingIcon
      //     },
      //     {
      //       title: 'Đơn hàng',
      //       href: '/order-management',
      //       icon: LocalShippingIcon
      //     },

      //     {
      //       title: 'Đơn chuyển trạm',
      //       href: '/order-switch-management',
      //       icon: LocalShippingIcon
      //     },
      //     {
      //       title: 'Tuyến đường',
      //       href: '/route-management',
      //       icon: LocalShippingIcon
      //     },
      //     {
      //       title: 'Phương tiện',
      //       href: '/vehicle-management',
      //       icon: LocalShippingIcon
      //     },
      //     {
      //       title: 'Khách hàng',
      //       href: '/company-management',
      //       icon: CardTravelIcon
      //     },
      //     {
      //       title: 'Người dùng',
      //       href: '/driver-management',
      //       icon: PermIdentityIcon
      //     },
      //     {
      //       title: 'Thầu phụ',
      //       href: '/assistant-management',
      //       icon: AssistantIcon
      //     },
      //     {
      //       title: 'Chi phí',
      //       href: '/expenses-management',
      //       icon: CardTravelIcon
      //     },
      //     {
      //       title: 'Ví tiền',
      //       href: '/wallet-management',
      //       icon: AccountBalanceWalletIcon
      //     }
      //   ]
      // },
      {
        title: 'Đổi mật khẩu',
        href: '/change-password',
        icon: LockIcon
      }

      // {
      //   title: 'Dashboards',
      //   href: '/dashboards',
      //   icon: DashboardIcon,
      //   children: [
      //     {
      //       title: 'Default',
      //       href: '/dashboards/default'
      //     },
      //     {
      //       title: 'Analytics',
      //       href: '/dashboards/analytics'
      //     }
      //   ]
      // }
      // {
      //   title: 'Quản lý bản đồ',
      //   href: '/location',
      //   icon: LocationOnIcon
      // },
      // {
      //   title: 'Live camera',
      //   href: '/live-camera',
      //   icon: VideocamIcon
      // },
      // {
      //   title: 'Lịch sử Video',
      //   href: '/camera-play-back',
      //   icon: FolderIcon
      // },
      // {
      //   title: 'Lịch sử hình ảnh',
      //   href: '/management/image',
      //   icon: ViewModuleIcon
      // },
      // {
      //   title: 'Quản lý phương tiện',
      //   href: '/trucks',
      //   icon: LocalShippingIcon
      // },
      // {
      //   title: 'Quản lý cảnh báo',
      //   href: '/warning',
      //   icon: WarningIcon
      // },
      // {
      //   title: 'Quản lý rule',
      //   href: '/rule',
      //   icon: GavelIcon
      // },
      // {
      //   title: 'Quản lý paper',
      //   href: '/paper',
      //   icon: ReceiptIcon
      // },
      // {
      //   title: 'Báo cáo',
      //   href: '/management',
      //   icon: BarChartIcon,
      //   children: [
      //     {
      //       title: 'Báo cái lái xe',
      //       href: '/management/driving-report',
      //       icon: PersonIcon
      //     },
      //     {
      //       title: 'chyến đi',
      //       href: '/management/excel-report',
      //       icon: CalendarTodayIcon
      //     },
      //     {
      //       title: 'Orders',
      //       href: '/management/orders'
      //     },
      //     {
      //       title: 'Order Details',
      //       href: '/management/orders/1'
      //     }
      //   ]
      // },

      // {
      //   title: 'Profile',
      //   href: '/profile',
      //   icon: PersonIcon,
      //   children: [
      //     {
      //       title: 'Timeline',
      //       href: '/profile/1/timeline'
      //     },
      //     {
      //       title: 'Connections',
      //       href: '/profile/1/connections'
      //     },
      //     {
      //       title: 'Projects',
      //       href: '/profile/1/projects'
      //     },
      //     {
      //       title: 'Reviews',
      //       href: '/profile/1/reviews'
      //     }
      //   ]
      // },
      // {
      //   title: 'Project',
      //   href: '/projects',
      //   icon: FolderIcon,
      //   children: [
      //     {
      //       title: 'Browse',
      //       href: '/projects'
      //     },
      //     {
      //       title: 'Create',
      //       href: '/projects/create'
      //     },
      //     {
      //       title: 'Overview',
      //       href: '/projects/1/overview'
      //     },
      //     {
      //       title: 'Files',
      //       href: '/projects/1/files'
      //     },
      //     {
      //       title: 'Activity',
      //       href: '/projects/1/activity'
      //     },
      //     {
      //       title: 'Subscribers',
      //       href: '/projects/1/subscribers'
      //     }
      //   ]
      // },
      // {
      //   title: 'Invoice',
      //   href: '/invoices/1',
      //   icon: ReceiptIcon
      // },
      // {
      //   title: 'Kanban Board',
      //   href: '/kanban-board',
      //   icon: ListAltIcon
      // },
      // {
      //   title: 'Mail',
      //   href: '/mail',
      //   icon: MailIcon,
      //   label: () => (
      //     <Label color={colors.red[500]} shape="rounded">
      //       2
      //     </Label>
      //   )
      // },
      // {
      //   title: 'Chat',
      //   href: '/chat',
      //   icon: ChatIcon,
      //   label: () => (
      //     <Label color={colors.red[500]} shape="rounded">
      //       4
      //     </Label>
      //   )
      // },
      // {
      //   title: 'Calendar',
      //   href: '/calendar',
      //   icon: CalendarTodayIcon,
      //   label: () => <Label color={colors.green[500]}>New</Label>
      // },
      // {
      //   title: 'Settings',
      //   href: '/settings',
      //   icon: SettingsIcon,
      //   children: [
      //     {
      //       title: 'General',
      //       href: '/settings/general'
      //     },
      //     {
      //       title: 'Subscription',
      //       href: '/settings/subscription'
      //     },
      //     {
      //       title: 'Notifications',
      //       href: '/settings/notifications'
      //     },
      //     {
      //       title: 'Security',
      //       href: '/settings/security'
      //     }
      //   ]
      // },
      // {
      //   title: 'Authentication',
      //   href: '/auth',
      //   icon: LockOpenIcon,
      //   children: [
      //     {
      //       title: 'Login',
      //       href: '/auth/login'
      //     },
      //     {
      //       title: 'Register',
      //       href: '/auth/register'
      //     }
      //   ]
      // },
      // {
      //   title: 'Errors',
      //   href: '/errors',
      //   icon: ErrorIcon,
      //   children: [
      //     {
      //       title: 'Error 401',
      //       href: '/errors/error-401'
      //     },
      //     {
      //       title: 'Error 404',
      //       href: '/errors/error-404'
      //     },
      //     {
      //       title: 'Error 500',
      //       href: '/errors/error-500'
      //     }
      //   ]
      // }
    ]
  }
  // {
  //   title: 'Support',
  //   pages: [
  //     {
  //       title: 'Presentation',
  //       href: '/presentation',
  //       icon: PresentToAllIcon
  //     },
  //     {
  //       title: 'Getting started',
  //       href: '/getting-started',
  //       icon: CodeIcon
  //     },
  //     {
  //       title: 'Changelog',
  //       href: '/changelog',
  //       icon: ViewModuleIcon,
  //       label: () => <Label color={colors.blue['500']}>v1.2.0</Label>
  //     }
  //   ]
  // }
];

export const marci = [
  {
    title: 'Pages',
    pages: [
      {
        title: 'Home',
        href: '/order-management',
        icon: HomeIcon
      },
      {
        title: 'Quản lý người dùng',
        href: '/driver-management',
        icon: PermIdentityIcon
      },
      {
        title: 'Quản lý đơn hàng',
        href: '/order-management',
        icon: LocalShippingIcon
      },
      {
        title: 'Quản lý chuyến đi',
        href: '/trip-management',
        icon: LocalShippingIcon
      },
      {
        title: 'Danh sách thông báo',
        href: '/notice-management',
        icon: AssistantIcon
      },
      {
        title: 'Báo cáo',
        icon: BarChartIcon,
        children: [
          {
            title: 'Tổng hợp công ty',
            href: '/general-report',
            icon: BarChartIcon
          },
          {
            title: 'Chi phí',
            href: '/price-report',
            icon: BarChartIcon
          }
        ]
      },
      {
        title: 'Đổi mật khẩu',
        href: '/change-password',
        icon: LockIcon
      }
    ]
  }
];

export const tms = [
  {
    title: 'Pages',
    pages: [
      {
        title: 'Home',
        href: '/order-management',
        icon: HomeIcon
      },
      {
        title: 'Quản lý đơn hàng',
        href: '/order-management',
        icon: LocalShippingIcon
      },
      {
        title: 'Danh sách thông báo',
        href: '/notice-management',
        icon: AssistantIcon
      }
    ]
  }
];
