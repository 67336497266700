import moment from 'moment';
import {
  getTripInfoApi,
  createTripApi,
  getSuggestApi,
  updateTripApi,
  tripCreateFormCostApi,
  getTripTempInfo,
  createTripTempApi
} from 'apis';
import { calculationFee } from 'utils/calculationFee';

const prefix = 'trip';

export const SET_ACTIVE_STEP = `${prefix}SET_ACTIVE_STEP`;
export const SET_COMPLETED = `${prefix}SET_COMPLETED`;
export const SET_GENERAL_INFO = `${prefix}SET_GENERAL_INFO`;
export const SET_COST_INFO = `${prefix}SET_COST_INFO`;
export const UPDATE_DETAIL_INFO = `${prefix}UPDATE_DETAIL_INFO`;
export const UPDATE_ORDER_IMPORT = `${prefix}UPDATE_ORDER_IMPORT`;
export const ADD_ORDER = `${prefix}ADD_ORDER`;
export const SET_DETAIL_CHOOSE = `${prefix}SET_DETAIL_CHOOSE`;
export const RESET_TRIP = `${prefix}RESET_TRIP`;
export const SET_DETAIL_INFO = `${prefix}SET_DETAIL_INFO`;
export const UPDATE_VALIDATE = `${prefix}UPDATE_VALIDATE`;
export const DELETE_ORDER = `${prefix}DELETE_ORDER`;
export const DUPLICATE_ORDER = `${prefix}DUPLICATE_ORDER`;
export const SET_SUGGEST = `${prefix}SET_SUGGEST`;
export const SET_IMAGES = `${prefix}SET_IMAGES`;
export const SET_COST_FORM = `${prefix}SET_COST_FORM`;
export const IMPORT_ORDER = `${prefix}IMPORT_ORDER`;

export const setActiveStep = activeStep => dispatch => {
  dispatch({
    type: SET_ACTIVE_STEP,
    activeStep
  });
};

export const setCompleted = completed => dispatch => {
  dispatch({
    type: SET_COMPLETED,
    completed
  });
};

export const setGeneralInfo = data => dispatch => {
  dispatch({
    type: SET_GENERAL_INFO,
    data
  });
};

export const setCostInfo = data => dispatch => {
  dispatch({
    type: SET_COST_INFO,
    data
  });
};

export const updateDetailInfo = data => dispatch => {
  dispatch({
    type: UPDATE_DETAIL_INFO,
    data
  });
};

export const addOrder = () => dispatch => {
  dispatch({
    type: ADD_ORDER
  });
};

export const setDetailChoose = data => dispatch => {
  dispatch({
    type: SET_DETAIL_CHOOSE,
    data
  });
};

export const resetTrip = () => dispatch => {
  dispatch({
    type: RESET_TRIP
  });
};

const getDetailTip = infoDetail => {
  return infoDetail.map(info => ({
    id_don_hang: info.id_don_hang,
    senderName: info.ten_nguoi_gui,
    senderNumber: info.sdt_nguoi_gui,
    senderAddress: info.dia_chi_nguoi_gui,
    receiverName: info.ten_nguoi_nhan,
    receiverAddress: info.dia_chi_nguoi_nhan,
    companyName: info.ten_cong_ty,
    receiverNumber: info.sdt_nguoi_nhan,
    assistantName: info.ten_thau_phu,
    tripType: info.loai_don_hang,
    itemType: info.loai_hang_hoa,
    itemDescription: info.mo_ta_hang_hoa,
    itemWeight: info.trong_luong,
    itemNumber: String(info.so_luong),
    itemCBM: info.cbm,
    inputItemPlate: info.noi_lay_hang,
    inputItemTime: new Date(info.ngay_gio_lay_hang),
    outputItemPlate: info.noi_tra_hang,
    outputItemTime: new Date(info.ngay_gio_tra_hang),
    cod: info.cod,
    transportFee: info.cuoc_van_chuyen,
    paymentStatus: info.thanh_toan,
    paymentType: info.hinh_thuc_thanh_toan,
    collector: info.nguoi_thu_tien,
    payerMoney: info.nguoi_thanh_toan,
    vat: info.vat,
    note: info.ghi_chu,
    images: info.list_anh
  }));
};

export const getTripInfo = (id, isTemp) => async dispatch => {
  try {
    const tripInfo = isTemp
      ? await getTripTempInfo(id)
      : await getTripInfoApi(id);
    const generalInformation = {
      ...tripInfo,
      km_dau: tripInfo.km_dau | 0,
      km_cuoi: tripInfo.km_cuoi | 0,
      numberDriver: tripInfo.lai_xe_3 === '' ? 2 : 3,
      assistantName: tripInfo.ten_thau_phu,
      assistantFee: tripInfo.chi_phi_thau_phu,
      checkType: tripInfo.ten_thau_phu !== '',
      plate: tripInfo.bien_so_xe,
      gio_nhac: tripInfo.gio_nhac,
      noi_dung_nhac: tripInfo.noi_dung_nhac,
      totalFee: tripInfo.tong_chi_phi,
      tong_km: tripInfo.tong_km,
      don_gia: tripInfo.don_gia,
      driver1: tripInfo.lai_xe_1,
      driver2: tripInfo.lai_xe_2,
      driver3: tripInfo.lai_xe_3,
      chuyen_di_image: tripInfo.chuyen_di_image
    };
    const detailInformation = getDetailTip(tripInfo.list_don_hang);
    dispatch({
      type: SET_DETAIL_INFO,
      generalInformation,
      detailInformation,
      list_form_chi_phi: tripInfo.list_form_chi_phi
    });
  } catch (error) {
    console.log(error);
  }
};

const convertDataDetail = detailInformation => {
  return detailInformation.map(detail => ({
    id_don_hang: detail.id_don_hang,
    ten_nguoi_gui: detail.senderName,
    sdt_nguoi_gui: detail.senderNumber,
    ten_cong_ty: detail.companyName,
    dia_chi_nguoi_gui: detail.senderAddress,
    ten_nguoi_nhan: detail.receiverName,
    sdt_nguoi_nhan: detail.receiverNumber,
    dia_chi_nguoi_nhan: detail.receiverAddress,
    ghi_chu: detail.note,
    ten_thau_phu: detail.assistantName,
    cod: detail.cod,
    cuoc_van_chuyen: Number(detail.transportFee),
    thanh_toan: detail.paymentStatus,
    hinh_thuc_thanh_toan: detail.paymentType,
    nguoi_thu_tien: detail.collector,
    nguoi_thanh_toan: detail.payerMoney,
    vat: detail.vat,
    ten_khach_hang: detail.customerName,
    loai_don_hang: detail.tripType,
    loai_hang_hoa: detail.itemType,
    mo_ta_hang_hoa: detail.itemDescription,
    trong_luong: detail.itemWeight,
    so_luong: Number(detail.itemNumber),
    cbm: detail.itemCBM,
    noi_lay_hang: detail.inputItemPlate,
    noi_tra_hang: detail.outputItemPlate,
    ngay_gio_lay_hang: moment(detail.inputItemTime).format(
      'YYYY-MM-DD HH:mm:ss'
    ),
    ngay_gio_tra_hang: moment(detail.outputItemTime).format(
      'YYYY-MM-DD HH:mm:ss'
    ),
    list_anh: detail.images
  }));
};

const convertData = tripInfo => {
  const {
    generalInformation,
    detailInformation,
    list_form_chi_phi,
    id
  } = tripInfo;
  const {
    driver1,
    driver2,
    driver3,
    plate,
    noi_dung_nhac,
    gio_nhac,
    tong_km,
    assistantName,
    assistantFee,
    don_gia
  } = generalInformation;
  const { tong_cuoc, tong_chi_phi, tong_lai } = calculationFee(
    detailInformation,
    generalInformation,
    list_form_chi_phi
  );
  return {
    ...generalInformation,
    tong_cuoc,
    tong_chi_phi,
    tong_lai,
    id_chuyen_di: id || '',
    ten_thau_phu: assistantName,
    chi_phi_thau_phu: Number(assistantFee),
    tong_km: Number(tong_km),
    don_gia: Number(don_gia),
    noi_dung_nhac,
    gio_nhac,
    lai_xe_2: driver2,
    lai_xe_3: driver3,
    lai_xe_1: driver1,
    bien_so_xe: plate,
    list_form_chi_phi,
    list_don_hang: convertDataDetail(detailInformation)
  };
};

export const createTrip = (
  isEdit,
  tripInfo,
  uiType,
  isTemp
) => async dispatch => {
  try {
    const data = convertData(tripInfo);
    let res;
    if (isEdit) res = await updateTripApi(data);
    else if (isTemp) res = await createTripTempApi(data);
    else res = await createTripApi(data);

    alert(res.message || `${isEdit ? 'Cập nhật' : 'Tạo'} đơn thành công`);
    dispatch(resetTrip);
    if (uiType && uiType === 'ver2') window.location.href = '/trip-management';
  } catch (error) {
    console.log(error);
    alert(
      `Có lỗi trong quá trình ${isEdit ? 'cập nhật' : 'tạo'
      } đơn, vui lòng thử lại sau`
    );
  }
};

export const updateValidate = data => dispatch => {
  dispatch({
    type: UPDATE_VALIDATE,
    data
  });
};

export const updateOrderImport = data => dispatch => {
  dispatch({
    type: UPDATE_ORDER_IMPORT,
    data
  });
};

export const deleteOrder = () => dispatch => {
  dispatch({
    type: DELETE_ORDER
  });
};

export const importOrders = data => dispatch => {
  dispatch({
    type: IMPORT_ORDER,
    orders: getDetailTip(data)
  });
};

export const duplicateOrder = () => dispatch => {
  dispatch({
    type: DUPLICATE_ORDER
  });
};

export const getSuggest = () => async dispatch => {
  const suggestion = await getSuggestApi();
  dispatch({
    type: SET_SUGGEST,
    suggestion
  });
};

export const getCostForm = () => async dispatch => {
  const res = await tripCreateFormCostApi();
  dispatch({
    type: SET_COST_FORM,
    list_form_chi_phi: res.list_form_chi_phi
  });
};
