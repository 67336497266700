import { getTripsApi, formTripList } from 'apis';

const prefix = 'tripManagement';

export const SET_FILTER_DATA = `${prefix}_SET_FILTER_DATA`;
export const SET_FILTER_FORM = `${prefix}_SET_FILTER_FORM`;
export const SET_TRIPS = `${prefix}_SET_TRIPS`;
export const SET_LOADING = `${prefix}_SET_LOADING`;
export const SET_LOADING_BOX = `${prefix}_SET_LOADING_BOX`;
export const SET_SELECTED_TRIP = `${prefix}_SET_SELECTED_TRIP`;
export const REMOVE_SELECTED_TRIP = `${prefix}_REMOVE_SELECTED_TRIP`;
export const SET_TAB_CHOICE = `${prefix}_SET_TAB_CHOICE`;

export const setFilter = data => dispatch => {
  dispatch({
    type: SET_FILTER_DATA,
    data
  });
};

export const setFilterForm = data => dispatch => {
  if (data.selected) {
    window.localStorage.setItem(
      'tms_local_selected_list_excel_filter',
      JSON.stringify(data.selected)
    );
  }
  dispatch({
    type: SET_FILTER_FORM,
    data
  });
};

export const setTrips = listTrip => dispatch => {
  dispatch({
    type: SET_TRIPS,
    listTrip
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const setLoadingBox = loadingBox => dispatch => {
  dispatch({
    type: SET_LOADING_BOX,
    loadingBox
  });
};

export const getTrips = (status, startDate, endDate) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const trips = await getTripsApi(status, startDate, endDate);
    dispatch(setTrips(trips));
  } catch (error) {
    console.log('get trip error ', error);
    dispatch(setLoading(false));
  }
};

export const getFilterForm = () => async dispatch => {
  try {
    const list = await formTripList();
    const newList = list.map(item => item.name);
    const localList =
      window.localStorage.getItem('tms_local_list_excel_filter') || '';
    const localSelectedList =
      window.localStorage.getItem('tms_local_selected_list_excel_filter') || '';

    if (localList && JSON.parse(localList).length !== newList.length) {
      window.localStorage.setItem(
        'tms_local_list_excel_filter',
        JSON.stringify(newList)
      );
    }

    dispatch(
      setFilterForm({
        list: newList,
        selected: localSelectedList ? JSON.parse(localSelectedList) : []
      })
    );
  } catch (error) {
    console.log('getFilterForm ', error);
  }
};

export const setSelectedTrip = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_TRIP,
    selected
  });
};

export const removeSelectedTrip = removed => dispatch => {
  dispatch({
    type: REMOVE_SELECTED_TRIP,
    removed
  });
};

export const setTabChoose = tabChoice => dispatch => {
  dispatch({
    type: SET_TAB_CHOICE,
    tabChoice
  });
};
