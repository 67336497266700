import * as actionTypes from 'actions/driverManagementActions';

const initialState = {
  drivers: [],
  driversMarci: [],
  filter: {
    admin: true,
    manager: true,
  },
  loading: false,
  loadingMarci: false,
  selectedDriver: [],
  formState: {
    open: false,
    edit: false,
    username: '',
    fullname: '',
    role: 'admin',
    ui_type: 'customer',
    avatar: '',
    email: '',
    role_name: '',
    note: '',
    ngay_bat_dau: new Date('2022/1/1')
  }
};

const driverManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DRIVER: {

      return action.getType === 'marci' ? {
        ...state,
        driversMarci: action.drivers,
        loadingMarci: false,
      } : {
        ...state,
        drivers: action.drivers,
        loading: false,
      };
    }

    case actionTypes.SET_FILTER: {
      return {
        ...state,
        filter: { ...state.filter, ...action.filter }
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }
    case actionTypes.SET_LOADING_MARCI: {
      return {
        ...state,
        loadingMarci: action.loading
      };
    }


    case actionTypes.SET_SELECTED_DRIVER: {
      return {
        ...state,
        selectedDriver: action.selected
      };
    }

    case actionTypes.UPDATE_FORM: {
      return {
        ...state,
        formState: { ...state.formState, ...action.formState }
      };
    }

    default: {
      return state;
    }
  }
};

export default driverManagementReducer;
