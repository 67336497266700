import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Hidden, Button } from '@material-ui/core';
import CardTravelIcon from '@material-ui/icons/CardTravel';

const useStyles = makeStyles(theme => ({
  root: {},
  summaryButton: {
    backgroundColor: theme.palette.white
  },
  barChartIcon: {
    marginRight: theme.spacing(1)
  },
  image: {
    width: '100%',
    maxHeight: 400
  }
}));

const Header = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const session = useSelector(state => state.session);
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        spacing={3}>
        <Grid item md={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Home
          </Typography>
          <Typography component="h1" gutterBottom variant="h3">
            Good Morning, {session.user.fullname}
          </Typography>
          {session.user.ui_type === 'customer' && (
            <>
              <Typography gutterBottom variant="subtitle1">
                Bạn có muốn tạo mẫu đơn hàng
              </Typography>
              <Button
                color="primary"
                component={RouterLink}
                edge="start"
                to={'/order/create'}
                variant="contained">
                <CardTravelIcon className={classes.barChartIcon} />
                Tạo ngay
              </Button>
            </>
          )}
          {session.user.ui_type === 'marci' && (
            <Button
              color="primary"
              component={RouterLink}
              edge="start"
              to={'/driver-management'}
              variant="contained">
              <CardTravelIcon className={classes.barChartIcon} />
              Quản lý người dùng
            </Button>
          )}

          {session.user.ui_type === 'tms' && (
            <Button
              color="primary"
              component={RouterLink}
              edge="start"
              to={'/order-management'}
              variant="contained">
              <CardTravelIcon className={classes.barChartIcon} />
              Quản lý đơn hàng
            </Button>
          )}
        </Grid>
        <Hidden smDown>
          <Grid item md={6}>
            <img
              alt="Cover"
              className={classes.image}
              src="/images/undraw_growth_analytics_8btt.svg"
            />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
