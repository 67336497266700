import { combineReducers } from 'redux';

import sessionReducer from './sessionReducer';
import drivingReportReducer from './drivingReportReducer';
import cameraPlaybackReducer from './cameraPlaybackReducer';
import excelReportReducer from './excelReportReducer';
import excelReportDetailReducer from './excelReportDetailReducer';
import imageReducer from './imageReducer';
import appReducer from './appReducer';
import locationReducer from './locationReducer';
import liveCameraReducer from './liveCameraReducer';
import truckReducer from './truckReducer';
import warningReducer from './warningReducer';
import ruleReducer from './ruleReducer';
import paperReducer from './paperReducer';
import tripReducer from './tripReducer';
import tripManagementReducer from './tripManagementReducer';
import orderManagementReducer from './orderManagementReducer';
import routeManagementReducer from './routeManagementReducer';
import orderSwitchStationReducer from './orderSwitchStationReducer';
import orderSwitchManagementReducer from './orderSwitchManagementReducer';
import VehicleManagementReducer from './VehicleManagementReducer';
import driverManagementReducer from './driverManagementReducer';
import assistantManagementReducer from './assistantManagementReducer';
import companyManagementReducer from './companyManagementReducer';
import scheduleReducer from './scheduleReducer';
import generalReportReducer from './generalReportReducer';
import revenueReportReducer from './revenueReportReducer';
import debtReportReducer from './debtReportReducer';
import noticeManagementReducer from './noticeManagementReducer';
import filterOrderReducer from './filterOrderReducer';
import excelManagementReducer from './excelManagementReducer';
import trashManagementReducer from './trashManagementReducer';
import expensesManagementReducer from './expensesManagementReducer';
import orderWaitingApproveManagementReducer from './orderWaitingApproveManagementReducer';
import orderWaitingApproveReducer from './orderWaitingApproveReducer';
import WalletManagementReducer from './WalletManagementReducer';
import orderReducer from './orderReducer';
import priceReportReducer from './priceReportReducer'

const rootReducer = combineReducers({
  session: sessionReducer,
  drivingReport: drivingReportReducer,
  cameraPlayback: cameraPlaybackReducer,
  excelReport: excelReportReducer,
  excelReportDetail: excelReportDetailReducer,
  imageReducer: imageReducer,
  app: appReducer,
  location: locationReducer,
  liveCamera: liveCameraReducer,
  truckReducer: truckReducer,
  warningReducer: warningReducer,
  ruleReducer: ruleReducer,
  paperReducer: paperReducer,
  tripReducer: tripReducer,
  tripManagementReducer: tripManagementReducer,
  orderManagementReducer: orderManagementReducer,
  routeManagementReducer: routeManagementReducer,
  orderSwitchStationReducer: orderSwitchStationReducer,
  orderSwitchManagementReducer: orderSwitchManagementReducer,
  VehicleManagementReducer: VehicleManagementReducer,
  driverManagementReducer: driverManagementReducer,
  assistantManagementReducer: assistantManagementReducer,
  companyManagementReducer: companyManagementReducer,
  scheduleReducer: scheduleReducer,
  generalReportReducer: generalReportReducer,
  revenueReportReducer: revenueReportReducer,
  debtReportReducer: debtReportReducer,
  noticeManagementReducer: noticeManagementReducer,
  filterOrderReducer: filterOrderReducer,
  excelManagementReducer: excelManagementReducer,
  trashManagementReducer: trashManagementReducer,
  expensesManagementReducer: expensesManagementReducer,
  orderWaitingApproveManagementReducer: orderWaitingApproveManagementReducer,
  orderWaitingApproveReducer: orderWaitingApproveReducer,
  WalletManagementReducer: WalletManagementReducer,
  orderReducer: orderReducer,
  priceReportReducer: priceReportReducer
});

export default rootReducer;
