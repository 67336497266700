import { useDispatch } from 'react-redux';
import { confirmTokenApi } from 'apis';
import { setInfo } from 'actions';
import { useEffect } from 'react';

export const useInitStoreAndConfirmToken = router => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const userInfo = await confirmTokenApi();
        dispatch(setInfo(userInfo));
      } catch (error) {
        router.history.push('/auth/login');
      }
    }
    getUserInfo();
    // eslint-disable-next-line
  }, [])
};
