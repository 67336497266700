import { getOrdersApi } from 'apis';

const prefix = 'orderManagement';

export const SET_FILTER_DATA = `${prefix}SET_FILTER_DATA`;
export const SET_ORDERS = `${prefix}SET_ORDERS`;
export const SET_LOADING = `${prefix}SET_LOADING`;
export const SET_SELECTED_ORDER = `${prefix}SET_SELECTED_ORDER`;
export const SET_TAB_CHOICE = `${prefix}SET_TAB_CHOICE`;

export const setFilter = data => dispatch => {
  dispatch({
    type: SET_FILTER_DATA,
    data
  });
};

export const setOrders = (listOrder, color) => dispatch => {
  dispatch({
    type: SET_ORDERS,
    listOrder,
    color
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const getOrders = (status, startDate, endDate) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const orders = await getOrdersApi(status, startDate, endDate);
    dispatch(setOrders(orders.data, orders.color));
  } catch (error) {
    console.log('get order error ', error);
    dispatch(setLoading(false));
  }
};

export const setSelectedOrder = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_ORDER,
    selected
  });
};

export const setTabChoose = tabChoice => dispatch => {
  dispatch({
    type: SET_TAB_CHOICE,
    tabChoice
  });
};
