/* eslint-disable react/no-multi-comp */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

export const FormatMoney = props => {
  const { inputRef, onChange, onBlur, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.value
          }
        });
        onBlur({
          target: {
            name: name,
            value: values.value
          }
        });
      }}
      suffix=" VND"
      thousandSeparator
    />
  );
};

FormatMoney.propTypes = {
  inputRef: PropTypes.func,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

export const formatMoneyText = value => {
  return (
    <NumberFormat
      displayType={'text'}
      suffix=" VND"
      thousandSeparator
      value={value}
    />
  );
};

const convertFormat = length => {
  if (length < 11) return '(###) ###-#####';
  else if (length === 11) return '(####) # ###-###';
  else return undefined;
};

export const FormatPhone = props => {
  const { inputRef, onChange, onBlur, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      format={convertFormat(other.value.length)}
      getInputRef={inputRef}
      mask=" "
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.value
          }
        });
        onBlur({
          target: {
            name: name,
            value: values.value
          }
        });
      }}
    />
  );
};

export const FormatPhoneNumber = props => {
  const { value } = props;

  return (
    <NumberFormat
      displayType={'text'}
      format={convertFormat(value.length)}
      value={value}
    />
  );
};

FormatPhone.propTypes = {
  inputRef: PropTypes.func,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

export const roundTimeQuarterHour = () => {
  var timeToReturn = new Date();

  timeToReturn.setHours(0);
  timeToReturn.setSeconds(0);
  timeToReturn.setMinutes(0);
  return timeToReturn;
};
