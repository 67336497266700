import { getListNotiApi } from 'apis';

const prefix = 'noticeManagement';

export const SET_NOTICES = `${prefix}_SET_NOTICES`;
export const SET_LOADING = `${prefix}_SET_LOADING`;
export const SET_SELECTED_NOTICE = `${prefix}_SET_SELECTED_NOTICE`;
export const UPDATE_FORM = `${prefix}_UPDATE_FORM`;
export const SET_FILTER_DATA = `${prefix}SET_FILTER_DATA`;

export const setFilter = data => dispatch => {
  dispatch({
    type: SET_FILTER_DATA,
    data
  });
};

export const setNotices = listNotice => dispatch => {
  dispatch({
    type: SET_NOTICES,
    listNotice
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const getNotices = (status, startDate, endDate) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const notices = await getListNotiApi(status, startDate, endDate);
    dispatch(setNotices(notices));
  } catch (error) {
    console.log('get notice error ', error);
    dispatch(setLoading(false));
  }
};

export const setSelectedNotice = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_NOTICE,
    selected
  });
};

export const updateForm = formState => dispatch => {
  dispatch({
    type: UPDATE_FORM,
    formState
  });
};
