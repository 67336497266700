import { getUsersApi } from 'apis';

const prefix = 'driverManagement';
export const SET_DRIVER = `${prefix}SET_DRIVER`;
export const SET_LOADING = `${prefix}SET_LOADING`;
export const SET_SELECTED_DRIVER = `${prefix}SET_SELECTED_DRIVER`;
export const UPDATE_FORM = `${prefix}UPDATE_FORM_DRIVER`;
export const SET_FILTER = `${prefix}SET_FILTER_DRIVER`;
export const SET_LOADING_MARCI = `${prefix}SET_LOADING_MARCI`;

export const setDrivers = (drivers, type) => dispatch => {
  dispatch({
    type: SET_DRIVER,
    drivers,
    getType: type
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const setLoadingMarci = loading => dispatch => {
  dispatch({
    type: SET_LOADING_MARCI,
    loading
  });
};

export const getDrivers = type => async dispatch => {
  type === 'marci'
    ? dispatch(setLoadingMarci(true))
    : dispatch(setLoading(true));
  try {
    const drivers = await getUsersApi(type);
    dispatch(setDrivers(drivers, type));
  } catch (error) {
    console.log('get Drivers error ', error);
    type === 'marci'
      ? dispatch(setLoadingMarci(true))
      : dispatch(setLoading(true));
  }
};

export const setSelectedDriver = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_DRIVER,
    selected
  });
};

export const updateForm = formState => dispatch => {
  if (formState.open === false) dispatch(getDrivers(formState.type));
  dispatch({
    type: UPDATE_FORM,
    formState
  });
};

export const setFilter = filter => dispatch => {
  dispatch({
    type: SET_FILTER,
    filter
  });
};
