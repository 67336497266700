import {
  getListCustomerApi,
  getCompaniesApi,
  getFilterOrdersApi,
  formOrderList,
  bsxApi
} from 'apis';
import { setTrips } from 'actions/tripManagementActions';

const prefix = 'filterOrder';

export const GET_FILTER_DATA = `${prefix}_GET_FILTER_DATA`;
export const SET_FILTER_DATA = `${prefix}_SET_FILTER_DATA`;
export const SET_FILTER_FORM = `${prefix}_SET_FILTER_FORM`;
export const SET_LOADING = `${prefix}_SET_LOADING`;
export const SET_ORDERS = `${prefix}_SET_ORDERS`;
export const SET_LINK = `${prefix}_SET_LINK`;

export const getFilterData = () => async dispatch => {
  const companies = await getCompaniesApi();
  const customers = await getListCustomerApi();
  const bsx = await bsxApi();
  dispatch({
    type: GET_FILTER_DATA,
    companies,
    customers,
    bsx
  });
};

export const setFilterForm = data => dispatch => {
  if (data.selected) {
    window.localStorage.setItem(
      'tms_local_selected_list_excel_filter_order',
      JSON.stringify(data.selected)
    );
  }
  dispatch({
    type: SET_FILTER_FORM,
    data
  });
};

export const getFilterForm = () => async dispatch => {
  try {
    const list = await formOrderList();
    const newList = list.map(item => item.name);
    const localList =
      window.localStorage.getItem('tms_local_list_excel_filter_order') || '';

    const localSelectedList =
      window.localStorage.getItem(
        'tms_local_selected_list_excel_filter_order'
      ) || '';

    if (localList && JSON.parse(localList).length !== newList.length) {
      window.localStorage.setItem(
        'tms_local_list_excel_filter_order',
        JSON.stringify(newList)
      );
    }
    dispatch(
      setFilterForm({
        list: newList,
        selected: localSelectedList ? JSON.parse(localSelectedList) : []
      })
    );
  } catch (error) {
    console.log('getFilterForm ', error);
  }
};

export const setFilter = data => dispatch => {
  dispatch({
    type: SET_FILTER_DATA,
    data
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const setOrders = listOrder => dispatch => {
  dispatch({
    type: SET_ORDERS,
    listOrder
  });
};

export const setLink = link => dispatch => {
  dispatch({
    type: SET_LINK,
    link
  });
};

export const getFilterOrder = (
  type,
  listFilter,
  startTime,
  endTime,
  list_form
) => async dispatch => {
  dispatch(setLoading(true));

  try {
    const orders = await getFilterOrdersApi(
      type,
      listFilter,
      startTime,
      endTime,
      list_form
    );
    if (type === 'bien_so_xe') {
      const data = {
        chuyen_di_array: orders.data,
        download_link: orders.link
      };
      dispatch(setTrips(data));
      dispatch(setLink(orders.link));
    } else {
      dispatch(setOrders(orders));
    }
  } catch (error) {
    console.log('get order error ', error);
    dispatch(setLoading(false));
  }
};
