import { getUsersApi, walletReportApi } from 'apis';

const prefix = 'walletManagement';
export const SET_DRIVER = `${prefix}SET_DRIVER`;
export const SET_LOADING = `${prefix}SET_LOADING`;
export const SET_SELECTED_DRIVER = `${prefix}SET_SELECTED_DRIVER`;
export const UPDATE_FORM = `${prefix}UPDATE_FORM_DRIVER`;
export const SET_FILTER = `${prefix}SET_FILTER_DRIVER`;
export const SET_RESPONSE = `${prefix}SET_RESPONSE`;

export const setDrivers = drivers => dispatch => {
  dispatch({
    type: SET_DRIVER,
    drivers
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const getUsers = () => async dispatch => {
  // dispatch(setLoading(true));
  try {
    const users = await getUsersApi();
    dispatch(setDrivers(users));
  } catch (error) {
    console.log('get users error ', error);
    // dispatch(setLoading(false));
  }
};

export const setSelectedDriver = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_DRIVER,
    selected
  });
};

export const setResponse = response => dispatch => {
  dispatch({
    type: SET_RESPONSE,
    response
  });
};

export const getList = (id, month) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const response = await walletReportApi(id, month);
    dispatch(setResponse(response));
  } catch (error) {
    console.log('get users error ', error);
    dispatch(setLoading(false));
  }
};

export const updateForm = formState => dispatch => {
  if (formState.open === false) dispatch(getUsers());
  dispatch({
    type: UPDATE_FORM,
    formState
  });
};

export const setFilter = filter => dispatch => {
  dispatch({
    type: SET_FILTER,
    filter
  });
};
