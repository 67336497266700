import axios from 'axios';
import { API_BASE_URL } from '../common/constants';
// import { io } from 'socket.io-client';

const instance = axios.create();

export default instance;

const request = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: false
});

const getAccessToken = () => {
  return window.localStorage.getItem('access_token');
};

request.interceptors.request.use(async config => {
  const token = await getAccessToken();
  if (token) config.headers['token'] = token;
  return config;
});

// const socket = io(`ws://marci.vn:8001/ws?token=${getAccessToken()}`);

// socket.on('connect', () => {
//   console.log(socket.id);
// });

export { request };
