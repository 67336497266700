import * as actionTypes from 'actions/noticeManagementActions';
import moment from 'moment';
import { roundTimeQuarterHour } from 'utils/format';

const initFilter = {
  startDate: moment(roundTimeQuarterHour())
    .subtract(2, 'days')
    .toDate(),
  endDate: moment(roundTimeQuarterHour())
    .add(3, 'days')
    .toDate(),
  status: 'toan_bo'
};
const initialState = {
  listNotice: [],
  filter: initFilter,
  loading: false,
  selectedNotice: [],
  tagNumber: 0,
  formState: { open: false, edit: false }
};

const noticeManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NOTICES: {
      return {
        ...state,
        listNotice: action.listNotice,
        tagNumber: action.tagNumber,
        loading: false,
        selectedNotice: []
      };
    }
    case actionTypes.SET_FILTER_DATA: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.data
        }
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_SELECTED_NOTICE: {
      return {
        ...state,
        selectedNotice: action.selected
      };
    }

    case actionTypes.UPDATE_FORM: {
      return {
        ...state,
        formState: { ...state.formState, ...action.formState }
      };
    }

    default: {
      return state;
    }
  }
};

export default noticeManagementReducer;
