import moment from 'moment';

import * as actionTypes from 'actions/filterOrderActions';
import { roundTimeQuarterHour } from 'utils/format';

const initFilter = {
  startDate: moment(roundTimeQuarterHour())
    .subtract(7, 'days')
    .toDate(),
  endDate: moment(roundTimeQuarterHour())
    .add(7, 'days')
    .toDate(),
  customer: [],
  company: [],
  bien_so_xe: []
};
const initialState = {
  listOrder: [],
  link: '',
  filter: initFilter,
  loading: false,
  customers: [],
  companies: [],
  bsx: [],
  filterFormExcel: {
    open: false,
    list: [],
    selected: []
  }
};

const filterOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTER_DATA: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.data
        }
      };
    }

    case actionTypes.SET_FILTER_FORM: {
      return {
        ...state,
        filterFormExcel: {
          ...state.filterFormExcel,
          ...action.data
        }
      };
    }

    case actionTypes.GET_FILTER_DATA: {
      return {
        ...state,
        customers: action.customers,
        companies: action.companies,
        bsx: action.bsx
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_LINK: {
      return {
        ...state,
        link: action.link
      };
    }

    case actionTypes.SET_ORDERS: {
      return {
        ...state,
        listOrder: action.listOrder.data,
        loading: false,
        link: action.listOrder.link
      };
    }

    default: {
      return state;
    }
  }
};

export default filterOrderReducer;
